.darkTheme .ant-switch-checked {
    background-color: #006b8c !important;
    border: none;
    outline: none;
}

.ant-switch:hover:not(.ant-switch-disabled) {
    background: rgba(255, 255, 255, 0.45);
    border: none;
    outline: none;
}

.darkTheme .ant-switch {
    border: none;
    outline: none;
}

.ant-switch .ant-switch-inner .ant-switch-inner-unchecked {
    margin-top: -53px;
    margin-inline-start: 0;
    margin-inline-end: 0;
}
