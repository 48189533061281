@import '../../../commonStyles.scss';

.ant-modal-confirm .ant-modal-confirm-body .ant-modal-confirm-title {
    color: #fff;
}

.ant-modal-confirm .ant-modal-content {
    background-color: #363636 !important;
    padding: 15px;
    min-width: 500px;
    @include mobileDevices {
        min-width: 250px;
    }
}

.ant-modal.ant-modal-confirm .ant-modal-confirm-content {
    color: #fff;
}

.ant-modal-confirm-btns .ant-btn-default {
    display: none;
}

.isCancel {
    .ant-modal-confirm-btns .ant-btn-default {
        display: inline-block !important;
        background-color: #201f1f;
        color: #fff;
        border-color: #201f1f;
    }
}

.ant-modal-confirm-btns .ant-btn-primary {
    background-color: #006b8c;
    outline: none;
}

.ant-modal-confirm .anticon-exclamation-circle svg {
    color: #006b8c;
}

.ant-btn-primary:not(:disabled):hover {
    color: #fff;
    background-color: #006b8c;
    outline: none;
}
.ant-btn-primary {
    color: #fff;
    background-color: #006b8c;
    outline: none !important;
}
