@import '../../../commonStyles.scss';

.customTitlePopup {
    .anticon-exclamation-circle {
        display: none;
    }

    .ant-modal-confirm-title {
        background: #006b8c;
        padding: 15px;
        margin: -32px;
        color: #fff !important;
        position: relative;
        margin-bottom: 40px;
    }

    .ant-modal-confirm-content {
        margin-left: 0 !important;
        font-size: 18px !important;
        font-weight: 500;
    }
}

.customTitlePopupError {
    .anticon-close-circle {
        display: none;
    }
}

.modelMessagePopup {
    .ant-modal-confirm-body {
        .ant-modal-confirm-title {
            font-size: 1rem;
        }
    }

    .ant-modal-confirm-btns {
        .ant-btn-primary {
            background: $primary-100;
            text-shadow: none;
            box-shadow: none;
            background-color: $primary-100;
            color: $light;
            padding: 10px 25px;
            font-size: $rts-3;
            min-width: 50px;
            border: 1px solid $primary-100;
            border-radius: 4px;
            height: 70px;

            @include Desktop1536 {
                height: 50px !important;
            }
        }
    }
}
.ant-modal {
    .ant-modal-content {
        background-color: #292e32 !important;
        color: #fff;
        .ant-modal-header {
            background-color: $primary-100;
            color: #fff;
            padding: 20px;

            @include Desktop1536 {
                padding: 16px 24px;
            }

            .ant-modal-title {
                color: #fff;
                font-size: 16px;
            }
        }

        .ant-modal-close-icon {
            svg {
                color: #fff;
            }

            &::after {
                width: 20px;
                height: 20px;
                top: 23px;
                position: absolute;
                visibility: visible;
                right: 15px;

                @include Desktop1536 {
                    top: 15px;
                }
            }
        }
    }
}

.ant-modal .ant-modal-content {
    position: relative;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    pointer-events: auto;
    padding: 0 0;
}

.darkTheme .addOrEditCustomer {
    background-color: #292e32 !important;
    color: #fff;
}

.darkTheme .ant-modal-header {
    background-color: $primary-100 !important;
    color: #fff;
}

.darkTheme .ant-modal-footer {
    background-color: #292e32 !important;
    color: #fff;
    padding-bottom: 25px;
    padding-right: 25px;
}

.darkTheme .ant-modal .ant-modal-content {
    background-color: #292e32 !important;
    color: #fff;
}
