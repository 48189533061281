@import "./commonStyles.scss";

.welcomeCard {
    float: left;
    width: 100%;
    background-color: #fafafa;
}

.welcomeText {
    color: #000;
    font-size: 3.9rem;
    text-align: center;
    font-weight: 600;
}

.blueText {
    color: #006b8c;
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
}

.cardBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #e9edf0;
    min-height: calc(100vh - 285px);
    border-radius: 4px;
    margin-top: 100px;
}

.description {
    font-size: 1.4rem;
    width: 55%;
    margin: 0 auto;
    margin-bottom: 20px;
}
.livelineLogo {
    max-width: 180px;
    margin-top: 10px;
}

.leftLogo {
    padding: 15px 0 20px 22px;
    float: left;
    box-sizing: border-box;
    width: 17%;
}

.footer {
    background-color: #f0f0f0;
    text-align: center;
    color: #666;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    float: left;
    width: 100%;
    margin-top: 120px;
}

html {
    background-color: #222;
    font-size: 13px;
}

.darkTheme .alignConfigurationSelect {
    display: flex;
    flex-direction: row;
    padding: 2px;
    flex-wrap: wrap;
    padding-top: 10px;

    @include mobileDevices {
        display: block;
        padding-right: 10px;
    }
    padding-left: 12px;

    .addcustomerLabel {
        font-size: 16px;
        color: rgb(241, 240, 240) !important;
        text-align: left;
        align-self: end;
        margin-bottom: 4px;
        display: block;
    }

    .clearBtn {
        margin-top: 30px;

        @include mobileDevices {
            margin-top: 0;
        }
    }

    .formControl {
        @include mobileDevices {
            margin-bottom: 10px;
        }
    }
}

.customerSelect {
    width: 200px;
    margin-right: 10px;

    @include mobileDevices {
        width: 100%;
    }
}

.facilitySelect {
    width: 200px;
    @include mobileDevices {
        width: 100%;
    }
}

.ant-spin-text {
    color: #f0f0f0 !important;
}

.ant-spin-dot-item {
    background-color: #f0f0f0 !important;
}
