@import '../../commonStyles.scss';

.logo {
    width: 85%;
    margin: 13px auto;
    display: block;
}

.logoCollapsed {
    width: 100%;
    margin: 13px auto;
    display: block;
}

.ant-layout-sider-children {
    background-color: $neutral-1004;
}

.darkTheme {
    .alignLeftNav {
        .ant-menu-title-content {
            &:hover {
                color: #fff;
            }
        }
        .ant-menu-item-selected {
            .ant-menu-title-content {
                &:hover {
                    color: #fff;
                }
            }
        }
    }
}

.ant-layout {
    .ant-layout-content {
        background: #212529;
        padding: 10px;
        margin: 15px;
    }

    .ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
        color: $light;
        background-color: #1b1d21b3;
    }

    .ant-menu-light .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected) {
        color: $light;
    }

    .ant-menu-submenu-title {
        border-radius: 8px;
    }
    .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover {
        background-color: $neutral-1006;
    }

    .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:active,
    .ant-menu-item-selected .ant-menu-submenu-title,
    .ant-menu-item-selected {
        background-color: $neutral-1006;
    }

    .ant-menu-item {
        &.ant-menu-item-active {
            background-color: $neutral-1006;
            color: $light;
        }
    }

    .ant-menu-item-selected {
        .ant-menu-title-content {
            color: #fff;
        }
    }
    .ant-menu-light .ant-menu-item:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected) {
        background-color: $neutral-1006;
    }
}

.mobileCollpase {
    display: none;

    &.ant-layout-trigger {
        display: none;
    }
}

.alignLeftNav {
    min-height: 100vh;
    background-color: $neutral-1004;
}
.sideMenuLogo {
    max-width: 26px;
}

.ant-menu-submenu {
    .sideMenuLogo {
        @include mobileDevices {
            margin-top: 4px;
        }
    }
}
.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):active {
    background-color: $neutral-1006;
}
.ant-menu-submenu-popup {
    &.ant-menu-light .ant-menu-item-selected {
        background-color: $neutral-1006;
    }

    .ant-menu {
        color: $neutral-1005;
        margin-left: -20px;
        margin-top: -5px;
        .ant-menu-item {
            &:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected) {
                color: $neutral-1005;
            }

            .sideMenuLogo {
                float: left;
                margin-top: 6px;
            }
        }
        .ant-menu-light .ant-menu-item-selected {
            @include mobileDevices {
                background-color: $neutral-1006;
            }
        }
    }
}

.darkTheme .ant-menu-title-content {
    color: #c9c9c9;
}

.ant-menu-scroll {
    max-height: 250px !important;
    overflow: auto !important;
}

.darkTheme .alignLeftNavCollapse {
    background-color: #212529 !important;
    min-height: calc(100vh);

    .ant-layout-sider {
        max-width: 80px !important;
        min-width: 80px !important;
        width: 160px !important;
    }
    .sideMenuLogo {
        align-items: center;
        width: 24px;
        margin-top: 8px !important;
    }
    .logo {
        width: 60px;
        margin: 13px 13px 13px 10px;
    }
}

.darkTheme .alignLeftNav {
    .ant-layout-sider {
        max-width: 200px;
        min-width: 200px;
        width: 200px;
    }
}

.ant-menu {
    background-color: #212529 !important;
    color: white;
}

.ant-menu-submenu {
    .ant-menu-submenu-placement-rightTop {
        .ant-menu-item-icon {
            float: left;
            margin-top: 4px;
        }
    }
}

.ant-menu-light .ant-menu-item-selected {
    color: #d5d5d5;
}

.ant-menu-submenu-popup .ant-menu-vertical > .ant-menu-item,
.ant-menu-submenu-popup .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 40px;
    line-height: 40px;
    color: #c9c9c9;
}

.ant-menu li {
    &:hover {
        color: #fff !important;
        background-color: #1a1d20 !important;
    }
}

.ant-menu .ant-menu-item-selected .ant-menu-title-content {
    color: white;
}

.ant-layout .ant-layout-sider {
    position: relative;
    min-width: 0;
    background: #212529;
    transition: all 0.2s;
}

.ant-layout .ant-layout-sider-trigger {
    position: fixed;
    bottom: 0;
    z-index: 1;
    height: 58px;
    color: #fff;
    line-height: 48px;
    text-align: center;
    background: #41484d;
    cursor: pointer;
    transition: all 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.darkTheme .ant-layout-sider-trigger .anticon svg {
    display: inline-block;
    color: #fff;
}

.mandatoryMsg {
    font-size: 16px;
    color: #f02645;
    text-align: left;
    margin-top: 4px;

    @include Desktop1536 {
        font-size: 13px;
    }
}
