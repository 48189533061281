.cancelFooterBtn {
    height: 38px;
    border: none ;
}

.cancelFooterBtn:hover {
    border: none;
    outline: none;
}

.okFooterBtn {
    height: 38px;
    border: none;
}