$primary-100: #006b8c; //Theme
$primary-200: #074f72;
$primary-300: #00539f; //Landing Tile Border
$primary-400: #92b1c0; //LightBackground
$primary-500: #1c8abf;

$light: #fff;
$neutral: #e9edf0;
$neutral-100: #fcfcfc;
$neutral-200: #f8f8f8;
$neutral-300: #f0f0f0;
$neutral-400: #d5d5d5; //border
$neutral-500: #e6e6e6;
$neutral-600: #bfbfbf;
$neutral-700: #a3a3a3;
$neutral-800: #8f969a;
$neutral-900: #666;
$neutral-1000: #5a5a5a;
$neutral-1001: #707070;
$neutral-1002: #f8f9fa;
$neutral-1003: #e2e2e2;
$neutral-1004: #f7f7f7;
$neutral-1005: #888a99;
$neutral-1006: #1a1d20;

$ternary: #e7d6ac;

$Dark: #222;
$Dark-100: #262a30;
$light: #fff;
$InProgress: #f00;
$Completed: #2cb90b;
$Delete: #dc584d;
$yellow: #eeb211;
$editIcon: #006b8c;
$sorterIcon: #1668dc;

$Typo-SemiBold: 600;
$Typo-Bold: bold;
$Typo-Regular: 400;
$Typo-medium: 500;
$rts-1: 0.9rem; //18px;
$rts-2: 1rem; //20px;
$rts-3: 1.2rem; //24px;
$rts-4: 1.4rem; //28px;
$rts-5: 1.8rem; //36px;
$rts-6: 0.8rem; //16px
$heading-1: 3rem; //60px;
$heading-2: 3.9rem; //78px;

@mixin LargeDesktop1900 {
    @media only screen and (min-width: 1900px) {
        @content;
    }
}

@mixin Desktop1536 {
    @media only screen and (max-width: 1536px) {
        @content;
    }
}

@mixin Desktop1280 {
    @media only screen and (max-width: 1280px) {
        @content;
    }
}

@mixin Desktop1200 {
    @media only screen and (max-width: 1200px) {
        @content;
    }
}

@mixin LargeDesktop1500 {
    @media only screen and (min-width: 1500px) {
        @content;
    }
}

@mixin LargeDesktop1344 {
    @media only screen and (min-width: 1344px) {
        @content;
    }
}

@mixin Desktop1100 {
    @media only screen and (max-width: 1100px) {
        @content;
    }
}

@mixin smallDesktop {
    @media only screen and (max-width: 1024px) {
        @content;
    }
}

@mixin Device1000 {
    @media only screen and (max-width: 1000px) {
        @content;
    }
}

@mixin tablet820 {
    @media only screen and (max-width: 820px) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (max-width: 768px) {
        @content;
    }
}

@mixin mobileDevices {
    @media only screen and (max-width: 600px) {
        @content;
    }
}

@mixin smallMobileDevices {
    @media only screen and (max-width: 375px) {
        @content;
    }
}

@mixin Desktop1800 {
    @media only screen and (max-width: 1860px) {
        @content;
    }
}

@mixin Desktop1920 {
    @media only screen and (min-width: 1920px) {
        @content;
    }
}
