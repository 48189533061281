.ant-tabs-top > .ant-tabs-nav {
    margin: 0 0 1px 0;
}

.darkTheme .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    position: relative;
    display: flex;
    flex: auto;
    align-self: stretch;
    overflow: hidden;
    white-space: nowrap;
    transform: translate(0);
    border-bottom: 1px solid #2a2f35;
}

.darkTheme .ant-tabs {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    display: flex;
    color: #ffff;
}
.darkTheme .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #f0f0f0;
    text-shadow: 0 0 0.25px currentcolor;
    background-color: #1b1d21;
}

.darkTheme .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-bottom: 1px solid #ffffff !important;
    background-color: #1b1d21;
    color: #f0f0f0 !important;
}

.darkTheme .ant-tabs-tab:hover {
    background-color: #1b1d21;
    color: #f0f0f0 !important;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    margin: 0;
    padding: 8px 16px;
    background: rgb(62 64 69 / 44%);
    border: 0;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible),
.ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible),
.ant-tabs .ant-tabs-tab-btn:active,
.ant-tabs .ant-tabs-tab-remove:active {
    color: #f0f0f0;
}

.ant-tabs-dropdown {
    .ant-tabs-dropdown-menu-item {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        min-width: 120px;
        margin: 0;
        padding: 4px 12px;
        color: #fff;
        font-weight: normal;
        font-size: 14px;
        line-height: 1.5714285714285714;
        cursor: pointer;
        transition: all 0.3s;
    }
    .ant-tabs-dropdown-menu {
        max-height: 200px;
        margin: 0;
        padding: 4px 0;
        overflow-x: hidden;
        overflow-y: auto;
        text-align: left;
        list-style-type: none;
        background-color: #2e3135;
        background-clip: padding-box;
        border-radius: 8px;
        outline: none;
        box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12),
            0 9px 28px 8px rgba(0, 0, 0, 0.05);
    }
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
    position: relative;
    padding: 8px 16px;
    background: transparent;
    border: 0;
    color: #fff;
}
